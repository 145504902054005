<template>
  <v-row>
    <v-col>
      <v-data-table
        :items="pendingImports"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    pendingImports: { type: Array, required: true },
  },
  data: () => ({
    headers: [
      { value: "id", text: "ID", sortable: false },
      { value: "name", text: "Nombre", sortable: false },
      { value: "scheduleDate", text: "Programado para", sortable: false },
    ],
  }),
};
</script>

<style scoped></style>
